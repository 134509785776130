<template>
  <div>
    <v-layout>
      <v-btn icon class="primary--text mt-1 mr-2" :to="`/`" router exact>
        <v-icon>keyboard_backspace</v-icon>
      </v-btn>

      <page-title>Device Provisioning</page-title>

      <div class="ml-4 mt-1 mb-2">
        <v-btn class="primary--text mr-3" text @click="refresh()">
          <v-icon class="mr-1">refresh</v-icon>
          Refresh
        </v-btn>
        <!-- TODO: button to add a device -->
        <device-registration-token-create></device-registration-token-create>
        <provision-device></provision-device>
       <!-- <provision-device-universal></provision-device-universal> -->
      </div>
    </v-layout>

    <v-layout>
      <page-subtitle
        >Create, view and manage your device registration tokens here. Use these tokens to add new devices to the current project.</page-subtitle
      >
    </v-layout>

    <v-layout>
      <v-alert
        dense
        border="left"
        type="warning"
        outlined
        v-if="project.applicationCount >= project.quota.applications"
      >
        You have reached your applications quota. To create new application
        either delete an existing one or contact support to increase your
        project's quota.
      </v-alert>
    </v-layout>

    <!-- delete confirmation modal -->
    <v-dialog v-model="modal" max-width="500px">
      <v-card>
        <v-card-title>Confirmation Required</v-card-title>
        <v-card-text>
          Are you sure want to delete token '{{ removingToken.id }}'?
        </v-card-text>
        <v-card-actions>
          <v-btn text v-on:click.native="modal = false" class="primary">Cancel</v-btn>
          <v-spacer>
          </v-spacer>
          <v-btn v-on:click.native="deleteRegistrationToken(removingToken)" text class="primary--text">
            Remove
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!-- edit labels -->
    <v-dialog v-model="editLabelsModal" max-width="400px">
      <v-card>
        <v-card-title>Edit Labels for "{{ editingToken.name }}"</v-card-title>
        <v-card-text>
          <v-list dense>
            <v-subheader>Current labels</v-subheader>
            <v-list-item
              v-for="(label, key) in editingToken.labels"
              :key="key"
            >
              <v-list-item-content>
                <v-chip outlined color="primary" class="justify-center">
                  {{ key }}:{{ label }}
                </v-chip>
              </v-list-item-content>
              <v-list-item-icon>
                <v-btn icon color="error" @click="deleteTokenLabel(key)">
                  <v-icon>delete</v-icon>
                </v-btn>
              </v-list-item-icon>
            </v-list-item>
          </v-list>
        </v-card-text>
        <v-card-title>Add New</v-card-title>
        <v-card-text>
          <form v-on:submit.prevent="addTokenLabel()">
            <v-text-field required label="Key" v-model="newLabel.key" />
            <v-text-field required label="Value" v-model="newLabel.value" />
            <v-btn v-show="false" hidden type="submit"></v-btn>
          </form>
          <div v-if="error">
            <v-alert error close>
              {{ error }}
            </v-alert>
          </div>
        </v-card-text>
        <v-card-actions>
          <v-btn
            text
            v-on:click.native="closeTokenEditModal()"
            class="primary--text"
            >Cancel</v-btn
          >
          <v-spacer> </v-spacer>
          <v-btn
            v-on:click.native="addTokenLabel()"
            class="primary white--text"
          >
            Add
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="editTokenModal" max-width="400px">
      <v-card>
        <v-card-title class="mb-5">Edit registration token "{{ editingToken.name }}"</v-card-title>
        <v-card-text>
          <form v-on:submit.prevent="updateToken()">
            <v-row align="center">
              <v-select
                :items="namingStrategies"
                label="Naming Strategy"
                v-model="editingToken.namingStrategy"               
                item-text="name"
                item-value="payload"
                persistent-hint
                hint="Device naming strategy"
                solo
              ></v-select>
            </v-row>
            <v-btn v-show="false" hidden type="submit"></v-btn>
          </form>
          <div v-if="error">
            <v-alert error close>
              {{ error }}
            </v-alert>
          </div>
        </v-card-text>
        <v-card-actions>
          <v-btn
            text
            v-on:click.native="closeTokenEditModal()"
            class="primary--text"
            >Close</v-btn
          >
          <v-spacer> </v-spacer>
          <v-btn
            v-on:click.native="updateToken()"
            class="primary white--text"
          >
            Update
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    
    <v-dialog v-model="quickTokenModal" width="400px" >
      <v-card  >
        <v-card-title class="mb-2">Quick registration token for "{{ editingToken.name }}"</v-card-title>          
        <v-card-text >
          <v-row align="center" justify="center" > 
              <v-card class="pa-md-4" min-height="50px" width="200px">
                <markup align="center" language="bash" :code="this.quickToken"></markup>
                <v-progress-linear
                    v-model="countdownTimerValue"
                    class="pa-md-4"
                    color="amber"
                    height="25"
                    width="200px"
                  >
                    Expire in: <div  max-width="400px"  id="countdownText"></div>
                  </v-progress-linear>
              </v-card>
          </v-row>

          <div v-if="error">
            <v-alert error close>
              {{ error }}
            </v-alert>
          </div>
        </v-card-text>
        <v-card-actions>
          <v-btn
            text
            v-on:click.native="closeQuickTokenModal()"
            class="primary--text"
            >Close</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-layout class="mt-2">
      <div class="tbcard">
        <div v-if="!loading && registrationTokens.length === 0">
          <v-container fill-height align-content-space-around>
            <v-layout row justify-center>
              <v-flex xs4 class="text-md-center mt-5">
                <span class="headline">No Device Registration Tokens</span>
                <span class="subheading">
                  <page-subtitle class="text-md-center">
                    Create a device registration token that you can use in the agent
                    to register it to your Synpse project.
                  </page-subtitle>
                </span>
                <v-btn class="mr-3" color="info" disabled>View examples</v-btn>
              </v-flex>
            </v-layout>
          </v-container>
        </div>

        <v-card v-else outlined>
          <v-data-table
            :key="tableKey"
            :headers="headers"
            :items="registrationTokens"
            :search="e3"
          >
            <template v-slot:[`item.id`]="{ item }">
                {{ item.id }}
            </template>

            <template v-slot:[`item.namingStrategy`]="{ item }">
                <span v-if="item.namingStrategy.type !== ''">{{ item.namingStrategy.type }}</span>
                <span v-else>default</span>
            </template>

            <template v-slot:[`item.labels`]="{ item }">
              <v-chip
                outlined
                v-for="(key, label) in item.labels"
                :key="key"
                color="primary"
                class="mr-1"
                >{{ label }}:{{ item.labels[label] }}</v-chip
              >
            </template>

            <template v-slot:[`item.environmentVariables`]="{ item }">
              <v-chip
                outlined
                v-for="(key, envVar) in item.environmentVariables"
                :key="key"
                color="primary"
                class="mr-1"
                >{{ envVar }}:{{ item.environmentVariables[envVar] }}</v-chip
              >
            </template>

            <template v-slot:[`item.maxRegistrations`]="{ item }">
              <span v-if="item.maxRegistrations">{{ item.maxRegistrations }}</span>
              <span v-else>unlimited</span>
            </template>

            <template v-slot:[`item.createdAt`]="{ item }">
              <v-tooltip top :key="item.id">
                <template v-slot:activator="{ on: tooltip }">
                  <span v-on="{ ...tooltip }">
                    {{ item.createdAt | ago }}
                  </span>
                </template>
                <span>{{ item.createdAt | date }}</span>
              </v-tooltip>
            </template>

            <template v-slot:[`item.actions`]="{ item }">
              <v-menu class="items" top left transition="v-slide-y-transition">
                <template v-slot:activator="{ on: on }">
                  <v-btn
                    icon
                    v-on="on"
                    :class="
                      $store.state.theme === 'light' ? 'secondary--text' : ''
                    "
                  >
                    <v-icon>more_vert</v-icon>
                  </v-btn>
                </template>

                <v-list dense>
                  <v-list-item @click="removeRegistrationTokenIntent(item)">
                    <v-list-item-title>delete</v-list-item-title>
                  </v-list-item>
                  <v-list-item @click="openLabelsEditModal(item)">
                    <v-list-item-title>edit labels</v-list-item-title>
                  </v-list-item>
                  <v-list-item @click="openTokenEditModal(item)">
                    <v-list-item-title>edit settings</v-list-item-title>
                  </v-list-item>
                  <v-list-item @click="openQuickTokenModal(item)">
                    <v-list-item-title>get quick token</v-list-item-title>
                  </v-list-item>
                  <!-- <v-list-item
                    :to="{
                      name: 'applicationDetails',
                      params: {
                        project: item.projectId,
                        namespace: item.namespaceId,
                        application: item.id,
                      },
                    }"
                    router
                  >
                    <v-list-item-title>details</v-list-item-title>
                  </v-list-item> -->
                </v-list>
              </v-menu>
            </template>
          </v-data-table>
        </v-card>
      </div>
    </v-layout>
  </div>
</template>

<style lang="stylus">
.tbcard {
  width: 100%;
  display: block;
}
</style>

<script>
import Markup from '../helpers/Markup';
import PageSubtitle from '../PageSubtitle';
import PageTitle from '../PageTitle';
import DeviceRegistrationTokenCreate from './DeviceRegistrationTokenCreate.vue';
import ProvisionDevice from './ProvisionDevice.vue';
// import ProvisionDeviceUniversal from './ProvisionDeviceUniversal.vue';

export default {
  components: {
    Markup,
    PageSubtitle,
    PageTitle,
    DeviceRegistrationTokenCreate,
    ProvisionDevice,
   // ProvisionDeviceUniversal,
  },
  data() {
    return {
      tableKey: '',
      e3: null,
      headers: [
        { text: 'ID', align: 'left', value: 'id', sortable: true },
        { text: 'Name', align: 'left', value: 'name', sortable: true },
        { text: 'Registered Devices', align: 'left', value: 'deviceCount', sortable: true },
        { text: 'Max registrations', align: 'left', value: 'maxRegistrations', sortable: true },
        { text: 'Labels', value: 'labels', align: 'left' },
        { text: 'Env', value: 'environmentVariables', align: 'left' },
        { text: 'Naming', value: 'namingStrategy', align: 'left' },
        { text: 'Description', value: 'description', align: 'left' },
        { text: 'Created', value: 'createdAt', align: 'left' },
        { text: '', value: 'actions', sortable: false, align: 'right' }
      ],
      namingStrategies: [
        {
          name: "Default (random names)",     
          payload: {
            type: "default", 
          },        
        },
        {
          name: "From device hostname",
          payload: {
            type: "fromHostname", 
          },  
        }
      ],
      modal: false,
      removingToken: {
        id: '',
        name: ''
      },
      quickToken: '',
      quickTokenTimerValue: 0,
      // Token labels edit
      editLabelsModal: false,
      editTokenModal: false,
      quickTokenModal: false,
      editingToken: {
        id: '',
        projectId: ''
      },
      newLabel: {
        key: '',
        value: ''
      }
    }
  },

  computed: {
    projectId() {
      return this.$route.params.project
    },
    loading() {
      return this.$store.state.device.loading
    },
    error() {
      return this.$store.state.device.error
    },
    registrationTokens() {
      return this.$store.state.device.registrationTokens
    },
    registrationToken() {
      return this.$store.state.device.registrationToken
    },
    project() {
      return this.$store.state.project.project
    }
  },

  mounted() {
    // Loading the project to get the quota
    let q = {
      projectId: this.projectId
    }
    this.$store.dispatch('GetProject', q)
    this.loadDeviceRegistrationTokens()
  },
  methods: {
    refresh() {
      this.loadDeviceRegistrationTokens()
    },
    beforeDestroy() {
      clearInterval(this.quickTokenTimer);
    },
    loadDeviceRegistrationTokens() {
      let q = {
        projectId: this.projectId
      }
      this.$store.dispatch('ListDeviceRegistrationTokens', q)
    },
    removeRegistrationTokenIntent (token) {
      // assigning removing input val
      this.removingToken = token
      this.modal = true
    },
    deleteRegistrationToken () {
      this.$store.dispatch('DeleteDeviceRegistrationToken', this.removingToken).then(() => {
        this.loadDeviceRegistrationTokens()
        // closing confirmation modal
        this.modal = false
      })
    },
    openLabelsEditModal(editingToken) {
      this.editLabelsModal = true
      this.editingToken = Object.assign({}, editingToken)      
    },
    openTokenEditModal(editingToken) {
      this.editTokenModal = true
      this.editingToken =  Object.assign({}, editingToken)
    },
    closeQuickTokenModal() {
      clearInterval(this.quickTokenTimer);
      this.quickTokenModal = false
    },
    countdown() {
      clearInterval(this.quickTokenTimer);
      // re-declaring the timer and close function so setInterval could use them
      var timeleft = 180;
      var bumpTimeout = () => {
        this.quickTokenTimerValue++
      }
      var close = this.closeQuickTokenModal;
      this.quickTokenTimer = setInterval(function(){
        if(timeleft <= 0){
          close()
        }
        bumpTimeout();
        document.getElementById("countdownText").innerHTML =" "+ timeleft + " seconds";
        timeleft -= 1;
      }, 1000);
    },
    async openQuickTokenModal(editingToken) {
      let q = {
        projectId: this.projectId,
        id: editingToken.id
      }
      await this.$store.dispatch('GetDeviceRegistrationQuickToken', q)
      // store will update existing list with quick token value. get it out of the store
       for (var idx in this.registrationTokens){
        if (this.registrationTokens[idx].id === editingToken.id){
          this.quickToken = this.registrationTokens[idx].quickRegistrationToken
        }
      }
      this.editingToken = Object.assign({}, editingToken)
      this.countdown()
      this.quickTokenModal = true
    },

    closeTokenEditModal() {
      this.editLabelsModal = false
      this.editTokenModal = false
      this.refresh()
    },
    async deleteTokenLabel(key) {
      let payload = {
        projectId: this.editingToken.projectId,
        id: this.editingToken.id,
        key: key,
      }
      await this.$store.dispatch('DeleteDeviceRegistrationTokenLabel', payload)

      let q = {
        projectId: this.editingToken.projectId,
        id: this.editingToken.id,
      }
      await this.$store.dispatch('GetDeviceRegistrationToken', q)
      // refreshing device
      this.editingToken = this.registrationToken
    },
    async addTokenLabel() {
      let payload = {
        projectId: this.editingToken.projectId,
        id: this.editingToken.id,
        label: {
          key: this.newLabel.key.toString(),
          value: this.newLabel.value.toString(),
        },
      }      
      await this.$store.dispatch('SetDeviceRegistrationTokenLabel', payload)
      // If no error, refreshing the same registration token
      if (this.error === null) {
        let q = {
          projectId: this.editingToken.projectId,
          id: this.editingToken.id,
        }
        await this.$store.dispatch('GetDeviceRegistrationToken', q)
        // refreshing registration token
        this.editingToken = this.registrationToken

        this.newLabel.key = ''
        this.newLabel.value = ''
      }
    },
    async updateToken() {
      let payload = this.editingToken      
      await this.$store.dispatch('UpdateDeviceRegistrationToken', payload)
      // If no error, refreshing the same registartion token
      if (this.error === null) {
        let q = {
          projectId: this.editingToken.projectId
        }
        await this.$store.dispatch('ListDeviceRegistrationTokens', q)
        // refreshing registration token
        this.editingToken = this.registrationToken

        this.editingToken.id = ''
        this.editingToken.name = ''

        this.$store.dispatch('Notify', `Registration token updated`)

        this.editTokenModal = false
      }
    }
  }
}
</script>